import { FC } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { GridRow, colours, column } from "design-kit"

import {
  Copy,
  SectionHeading,
  Section,
} from "../views/StampDutyCalculator/Common"

import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"
import {
  WrappedHead,
  WrappedBase,
} from "../shared-components/Shared/WrappedBase"
import Hero from "../shared-components/Shared/Hero"
import Disclaimer from "../views/StampDutyCalculator/Disclaimer"
import HowThisCalculated from "../views/StampDutyCalculator/HowThisCalculated"
import EnglandAndNIRates from "../views/StampDutyCalculator/EnglandAndNIRates"
import ScotlandRates from "../views/StampDutyCalculator/ScotlandRates"
import WalesRates from "../views/StampDutyCalculator/WalesRates"
import FirstTimeRates from "../views/StampDutyCalculator/FirstTimeRates"
import BTLRates from "../views/StampDutyCalculator/BTLRates"
import CanIAddToMortgage from "../views/StampDutyCalculator/CanIAddToMortgage"
import FAQ from "../views/StampDutyCalculator/FAQ"
import ExplainerCards from "../views/StampDutyCalculator/ExplainerCards"

import AboutCard from "../shared-components/AboutCard"
import { ColourCardContainer } from "../shared-components/ColourCardContainer/ColourCardContainer"
import { StampDutyCalc } from "@heyhabito/stamp-duty-calculator"
import { SentryErrorBoundary } from "../shared-components/Shared/ErrorBoundary"

import config from "../../config.json"

const NarrowColumn = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 7,
  })}
`
const Narrow: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <GridRow>
    <NarrowColumn>{children}</NarrowColumn>
  </GridRow>
)

const WideColumn = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}
`
const Wide: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <GridRow>
    <WideColumn>{children}</WideColumn>
  </GridRow>
)

interface SEOData {
  title: string
  metaDescription: string
  propertyValuePence: number | null
  formattedPropertyValue: string | null
  h1Heading: string
  introParagraph: string
  canonicalUrl: string
}

// These defaults will be overwritten by the SEO versions of the page, in
// `scripts/CreatePages/StampDutyCalculatorSeo.js`
const defaultSeoData: SEOData = {
  title: "Stamp duty calculator 2025: how much to pay & when | Habito",
  metaDescription:
    "We’ll guide you through what stamp duty is, how it’s calculated, and how it impacts your mortgage application.",
  propertyValuePence: null,
  formattedPropertyValue: null,
  h1Heading: "Stamp Duty Calculator UK",
  introParagraph:
    "Stamp Duty Land Tax (SDLT, commonly known as just stamp duty) is a tax you pay when you buy property in the UK. Use this calculator to figure out what you’ll pay on your main home. For buy to lets and second homes, check the table below.",
  canonicalUrl: "https://www.habito.com/stamp-duty-calculator",
}

interface PageContext {
  metadata: SEOData
}

export const Head: FC<{ pageContext: PageContext }> = ({ pageContext }) => {
  const metadata = pageContext.metadata || defaultSeoData

  return (
    <WrappedHead
      metaTitle={metadata.title}
      metaDescription={metadata.metaDescription}
      canonicalUrl={metadata.canonicalUrl}
      noIndex={false}
      pageName="stamp_duty_calculator"
      intercom={true}
      config={config}
    />
  )
}

const ComparisonPage: FC<{
  pageContext: PageContext
}> = ({ pageContext }) => {
  const metadata = pageContext.metadata || defaultSeoData

  return (
    <WrappedBase>
      <div
        css={css`
          background-color: ${colours.white};
        `}
      >
        <Navbar sticky={true} />

        <main id="main-content">
          <Hero title={metadata.h1Heading}>
            <Copy>{metadata.introParagraph}</Copy>
          </Hero>

          <Narrow>
            <Section>
              <SectionHeading>Calculate your stamp duty</SectionHeading>

              <SentryErrorBoundary identifier="StampDuty">
                <StampDutyCalc
                  api={config.calculatorApiURL}
                  initialPropertyValue={metadata.propertyValuePence || 23000000}
                />
              </SentryErrorBoundary>
            </Section>

            <Disclaimer />
          </Narrow>

          <Wide>
            <Section>
              <SectionHeading>What would you like to do next?</SectionHeading>

              <ColourCardContainer
                cards={[
                  {
                    articleTitle: "Found a property? Get the perfect mortgage",
                    description:
                      "Tell us a bit about you, and we’ll guide you through the next steps, for free.",
                    buttonTitle: "Get started",
                    buttonHref: "https://www.habito.com/how-can-we-help",
                  },
                  {
                    articleTitle: "Read about stamp duty for first time buyers",
                    description:
                      "You could get tax relief and save thousands if you’re buying for the first time.",
                    buttonTitle: "Stamp duty relief",
                    buttonHref:
                      "https://www.habito.com/first-time-buyer-mortgages#stamp-duty-relief-for-first-time-buyers",
                  },
                  {
                    articleTitle: "See what other fees you pay on a mortgage",
                    description:
                      "Make sure you plan for all the costs – not just stamp duty.",
                    buttonTitle: "Read the article",
                    buttonDescriptiveTitle:
                      "Read the article about what other fees you pay on a mortgage",
                    buttonHref:
                      "https://www.habito.com/first-time-buyer-mortgages/mortgage-fees",
                  },
                ]}
              />
            </Section>
          </Wide>

          <Narrow>
            <HowThisCalculated
              formattedPropertyValue={metadata.formattedPropertyValue}
            />

            <FirstTimeRates />

            <EnglandAndNIRates />

            <ScotlandRates />

            <WalesRates />

            <BTLRates />

            <CanIAddToMortgage />
          </Narrow>

          <Wide>
            <Section>
              <AboutCard
                title="Habito is the largest, most-trusted online mortgage broker in the UK"
                content="We’ve helped over 550,000 people on the way to financing their home. Our powerful algorithm sorts through thousands of mortgages from more than 90 lenders to find the perfect one for you. We combine this with top-tier mortgage expertise to deliver the most personalised, fast and convenient way to get a mortgage today."
                href="https://www.habito.com/how-can-we-help/new-mortgage"
              />
            </Section>

            <FAQ />

            <ExplainerCards />
          </Wide>
        </main>

        <Footer />
      </div>
    </WrappedBase>
  )
}

export default ComparisonPage
