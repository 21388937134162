import { FC } from "react"
import styled from "@emotion/styled"
import {
  Body,
  Card as DKCard,
  Headline3,
  ArrowCircleRightIcon,
  breakpoints,
  colours,
  horizontal,
  vertical,
} from "design-kit"

const ArticleFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${breakpoints.tablet`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  `}
`

const CardContainer = styled(DKCard)`
  padding: 0;
  margin-bottom: ${vertical.s};

  ${breakpoints.tablet`
    padding: 0;
    width: calc(33.3% - 16px);
  `}
`

const Anchor = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-decoration: none;
  box-sizing: border-box;
  padding: ${vertical.m} ${horizontal.m};
`

const CardTitle = styled(Headline3)`
  color: ${colours.offBlack};
`

const CardContent = styled(Body)`
  color: ${colours.offBlack};
  margin: 16px 0 32px 0;
`

const ArrowContainer = styled.div`
  margin-top: auto;
  text-align: right;
`

export interface ArrowCardData {
  articleTitle?: string
  description?: string
  buttonHref?: string
}

export const ArrowCardContainer: FC<{ cards: ArrowCardData[] }> = ({
  cards,
}) => (
  <ArticleFlexContainer>
    {cards &&
      cards.map((item, index) =>
        item.buttonHref ? (
          <CardContainer element="div" key={index}>
            <Anchor href={item.buttonHref}>
              <div>
                <CardTitle spacing="none">{item.articleTitle}</CardTitle>
                <CardContent>{item.description || ""}</CardContent>
              </div>

              <ArrowContainer>
                <ArrowCircleRightIcon color={colours.offBlack} />
              </ArrowContainer>
            </Anchor>
          </CardContainer>
        ) : null
      )}
  </ArticleFlexContainer>
)
