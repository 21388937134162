import { FC } from "react"

import { TextLink } from "design-kit"

import {
  Table,
  Thead,
  Th,
  Td,
  Section,
  SectionHeading,
  Copy,
  Ul,
} from "./Common"

const BTLRates: FC = () => (
  <Section>
    <SectionHeading id="buy-to-let">
      Buy to let (or additional property) stamp duty rates
    </SectionHeading>

    <Copy>
      If you’re buying an additional property (for example, a place to let), you
      might need to pay more stamp duty. Stamp duty for additional properties is
      calculated in ‘bands’, which means you pay different rates on different
      ‘portions’ of the property price.
    </Copy>

    <Copy>
      Here are the stamp duty rates for England and Northern Ireland. (You can
      find the rates for{" "}
      <TextLink
        href="https://www.stampdutycalculator.org.uk/ltt-calculator.htm"
        text="Wales here"
      />{" "}
      and{" "}
      <TextLink
        href="https://www.stampdutycalculator.org.uk/stamp-duty-scotland.htm"
        text="Scotland here"
      />
      ).
    </Copy>

    <Table>
      <Thead>
        <tr>
          <Th>Property value</Th>
          <Th>Stamp duty rate</Th>
        </tr>
      </Thead>

      <tbody>
        <tr>
          <Td>£0 – £125,000</Td>
          <Td>5%</Td>
        </tr>
        <tr>
          <Td>£125,000 – £250,000</Td>
          <Td>7%</Td>
        </tr>
        <tr>
          <Td>£250,001 – £925,000</Td>
          <Td>10%</Td>
        </tr>
        <tr>
          <Td>£925,001 – £1.5million</Td>
          <Td>15%</Td>
        </tr>
        <tr>
          <Td>£1.5million+</Td>
          <Td>17%</Td>
        </tr>
      </tbody>
    </Table>

    <Copy>
      <strong>
        Here’s an example, for a buy-to-let property worth £650,000:
      </strong>
    </Copy>

    <Ul>
      <li> You’ll pay 5% on the first £125,000 = £6,250</li>
      <li> You’ll pay 7% on the next £125,000 = £8,750</li>
      <li> Then 10% on the next £400,000 = £40,000</li>
    </Ul>

    <Copy>Total stamp duty = £55,000</Copy>
  </Section>
)

export default BTLRates
