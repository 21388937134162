import React from "react"
import { RadioButtonGroup } from "design-kit"
import { InputContainer, CurrencyInput } from "./Input"
import {
  CalculateButton,
  Details,
  ResultPanel,
  WholeCalculatorContainer,
} from "./Display"

const noop = (): void => undefined

type BuyerType = "FirstTimeBuyer" | "NotFirstTimeBuyer"

type Region = "EnglandOrNorthernIreland" | "Scotland" | "Wales"

const AskPropertyPrice: React.FC<{
  propertyValue: number
  onUpdate: (_: number) => void
}> = ({ propertyValue, onUpdate }) => (
  <InputContainer
    title="What’s the property price?"
    tooltip={
      <React.Fragment>
        The total value of the property, including the deposit amount. If you’re
        not sure, an estimate is fine – you can always check back here when
        you’ve found a place.
      </React.Fragment>
    }
  >
    <CurrencyInput value={propertyValue} onUpdate={onUpdate} />
  </InputContainer>
)

const AskRegion: React.FC<{
  region: Region
  onUpdate: (_: Region) => void
}> = ({ region, onUpdate }) => (
  <InputContainer
    tooltip={
      <React.Fragment>
        You pay slightly different rates depending on whether you’re buying in
        England and Northern Ireland, Scotland or Wales. We’ll automatically
        calculate those for you here.
      </React.Fragment>
    }
  >
    <RadioButtonGroup
      name="region"
      title="Where are you buying?"
      buttons={[
        {
          value: "EnglandOrNorthernIreland",
          label: "In England or N. Ireland",
        },
        { value: "Scotland", label: "In Scotland" },
        { value: "Wales", label: "In Wales" },
      ]}
      selectedValue={region}
      onChange={onUpdate}
      layout="column"
    />
  </InputContainer>
)

const AskBuyerType: React.FC<{
  buyerType: BuyerType
  onUpdate: (_: BuyerType) => void
}> = ({ buyerType, onUpdate }) => (
  <InputContainer
    tooltip={
      <React.Fragment>
        If you’re a first time buyer, you’ll get some relief on stamp duty.
        We’ll automatically calculate that for you here.
      </React.Fragment>
    }
  >
    <RadioButtonGroup
      name="buyerType"
      title="Is this your first property purchase?"
      buttons={[
        { value: "FirstTimeBuyer", label: "Yes" },
        { value: "NotFirstTimeBuyer", label: "No" },
      ]}
      selectedValue={buyerType}
      onChange={onUpdate}
      layout="row"
    />
  </InputContainer>
)

const gbpFormat = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  maximumFractionDigits: 0,
})

const fetchStampDuty = async (
  api: string,
  propertyValue: number,
  region: Region,
  buyerType: BuyerType
): Promise<string> => {
  if (!isFinite(propertyValue) || propertyValue <= 0) {
    throw new TypeError("Please enter a valid property value")
  }

  const response = await fetch(
    `${api}/v3/stamp-duty/${buyerType}/${propertyValue * 100}/${region}`,
    {
      headers: { Authorization: "Basic SGFiaXRvOiE4T3VWOCRBI05MNA==" },
    }
  )

  if (response.status !== 200) {
    const body = await response.text()
    throw new Error(
      "Error calling /api/calculator/v3/stamp-duty: " +
        response.status +
        "; " +
        body
    )
  }

  const blob = await response.json()

  if (typeof blob.stampDutyPost1stApril2025 !== "number") {
    throw new TypeError("Expected stampDutyPost1stApril2025 to be a number")
  }

  return gbpFormat.format((blob.stampDutyPost1stApril2025 / 100) | 0)
}

const useCalculator = (
  api: string,
  setStampDutyValue: (_: null | string) => void,
  validInput: boolean,
  propertyValue: number,
  region: Region,
  buyerType: BuyerType
): [() => void, boolean] => {
  const [worker, setWorker] = React.useState<null | { cancel: () => void }>(
    null
  )

  const calculate = React.useCallback((): void => {
    if (worker !== null) {
      worker.cancel()
    }

    setStampDutyValue(null)

    if (!validInput) {
      setWorker(null)
      return
    }

    let mutHandler: (_: string) => void = setStampDutyValue

    // note: explicit functions to defer referencing mutable handler
    const onSuccess = (result: string): void => mutHandler(result)
    const onError = (_error: Error): void => mutHandler("Service unavailable.")

    setWorker({
      cancel: () => {
        mutHandler = noop
      },
    })

    fetchStampDuty(api, propertyValue, region, buyerType).then(
      onSuccess,
      onError
    )
  }, [api, validInput, propertyValue, region, buyerType])

  React.useEffect(worker === null ? noop : calculate, [
    propertyValue,
    region,
    buyerType,
  ])

  return [calculate, worker !== null]
}

export const StampDutyCalc: React.FC<{
  api: string
  initialPropertyValue: number
}> = ({ api, initialPropertyValue }) => {
  const [propertyValue, setPropertyValue] = React.useState<number>(
    (initialPropertyValue / 100) | 0
  )
  const [region, setRegion] = React.useState<Region>("EnglandOrNorthernIreland")
  const [buyerType, setBuyerType] = React.useState<BuyerType>("FirstTimeBuyer")
  const [stampDutyValue, setStampDutyValue] = React.useState<null | string>(
    null
  )
  const validInput = propertyValue > 0
  const [calculate, requested] = useCalculator(
    api,
    setStampDutyValue,
    validInput,
    propertyValue,
    region,
    buyerType
  )
  return (
    <WholeCalculatorContainer>
      <Details>
        <AskPropertyPrice
          propertyValue={propertyValue}
          onUpdate={setPropertyValue}
        />
        <AskRegion region={region} onUpdate={setRegion} />
        {region === "EnglandOrNorthernIreland" && (
          <AskBuyerType buyerType={buyerType} onUpdate={setBuyerType} />
        )}
        <CalculateButton calculate={calculate} />
      </Details>
      {requested && validInput && (
        <ResultPanel
          headline={
            region === "EnglandOrNorthernIreland" &&
            buyerType === "FirstTimeBuyer"
              ? "As a first time buyer you'll pay:"
              : "You'll pay:"
          }
          stampDutyValue={stampDutyValue}
        />
      )}
    </WholeCalculatorContainer>
  )
}
