import { FC } from "react"

import { TextLink } from "design-kit"

import {
  Table,
  Thead,
  Th,
  Td,
  Section,
  SectionHeading,
  Copy,
  Ul,
} from "./Common"

const EnglandAndNIRates: FC = () => (
  <Section>
    <SectionHeading>
      England & Northern Ireland SDLT (stamp duty) rates
    </SectionHeading>
    <Copy>
      Stamp Duty Land Tax (most people just call it ‘stamp duty’) is calculated
      in ‘bands’, which means you pay different rates on different ‘portions’ of
      the property price.
    </Copy>

    <Table>
      <Thead>
        <tr>
          <Th>Property price</Th>
          <Th>Stamp duty rate</Th>
        </tr>
      </Thead>

      <tbody>
        <tr>
          <Td>£0 – £125,000</Td>
          <Td>0%</Td>
        </tr>
        <tr>
          <Td>£125,001 - £250,000</Td>
          <Td>2%</Td>
        </tr>
        <tr>
          <Td>£250,001 – £925,000</Td>
          <Td>5%</Td>
        </tr>
        <tr>
          <Td>£925,001 – £1,500,000</Td>
          <Td>10%</Td>
        </tr>
        <tr>
          <Td>£1,500,001+</Td>
          <Td>12%</Td>
        </tr>
      </tbody>
    </Table>

    <Copy>
      <strong>Here’s an example for a property worth £650,000:</strong>
    </Copy>
    <Ul>
      <li>You’ll pay 0% on the first £125,000 = £0</li>
      <li>You’ll pay 2% on the next £125,000 = £2,500</li>
      <li>Then 5% on the next £400,000 = £20,000</li>
    </Ul>

    <Copy>Total stamp duty = £22,500</Copy>

    <Copy>
      You can also use the{" "}
      <TextLink
        href="https://www.tax.service.gov.uk/calculate-stamp-duty-land-tax/#/intro"
        text="GOV.UK calculator for SDLT in England and Northern Ireland"
      />
      .
    </Copy>
  </Section>
)

export default EnglandAndNIRates
