import React, { ReactNode } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import {
  Error,
  breakpoints,
  colours,
  horizontal,
  mixins,
  typographyStyles,
  vertical,
} from "../../index"

import { Layout, defaultSameLayoutString } from "../../variables/layout/Layout"
import QuestionText from "./Internals/QuestionText"

const FieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: 0;
  margin: 0;
  padding: 0;

  :disabled {
    pointer-events: none;

    > div {
      opacity: 0.5;
    }
  }
`

const rowLayoutStyles = `
  flex-direction: row;
  align-items: center;

  > label {
    margin: 0 ${horizontal.s} 0 0;
    &:last-child {
      margin-right: 0;
    }
  }
`

const columnLayoutStyles = `
  flex-direction: column;
  align-items: stretch; // default value

  > label {
    margin: ${vertical.xxs} 0 0 0;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const RadioButtonContainer = styled.div<{ layout: Layout<RadioButtonLayout> }>`
  height: auto;
  display: flex;

  ${props => css`
    ${props.layout.mobile === "row" ? rowLayoutStyles : columnLayoutStyles};

    ${breakpoints.tablet`
      ${props.layout.tablet === "row" ? rowLayoutStyles : columnLayoutStyles};
    `}

    ${breakpoints.desktop`
      ${props.layout.desktop === "row" ? rowLayoutStyles : columnLayoutStyles};
    `}
  `}
`

/* Although InvisibleTabbableInput is positioned 'absolutely', its width and
 * height values are relative to the closest ancestor that doesn't have the
 * default `position: static`. We want Label to determine the size
 * of InvisibleTabbableInput, so we set RadioButtonInner's position to
 * `relative` but then leave it unmoved relative to its original position.
 */
const Label = styled.label`
  position: relative;

  flex: 1; // makes each label take up the same amount of space

  // removes grey flashing box when clicking a radio button in ios (#7678)
  -webkit-tap-highlight-color: transparent;
`

/* When you tab to an input, the browser will helpfully scroll the viewport to
 * show all of the input element.
 *
 * We want to take advantage of this to make sure we show all of our Glorious
 * Custom Radio Button, including, importantly, its text!
 *
 * To do this, we make the `input` element take the size and position of our
 * custom beauty, using absolute positioning.
 */
const InvisibleTabbableInput = styled.input`
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  // this is acceptable a11y-wise
  // without this we can get two focus indicators, we only want the one that we
  // introduce on RadioButtonInner
  outline: 0;

  // hide the browser's, now weirdly big, radio button
  // we'll replace it with our own tastefully sized one
  opacity: 0;

  cursor: pointer;
`

const RadioButtonInner = styled.div`
  min-height: 48px;
  // 12px padding-top + 12px padding-bottom + 24px line height = 48px
  padding: 12px ${horizontal.s};
  margin-right: ${horizontal.s};
  display: flex;
  justify-content: flex-start;

  border-radius: 8px;
  border: 1px solid ${colours.offBlack};
  box-sizing: border-box;
  background-color: ${colours.white};

  &:last-child {
    margin-right: 0;
  }

  input[type="radio"]:focus + & {
    ${mixins.focused};
  }

  input[type="radio"]:hover + & {
    background-color: ${colours.greyScale.grey25};
  }

  input[type="radio"]:checked + & {
    color: ${colours.white};
    background-color: ${colours.offBlack};
  }
`

const RadioButtonCircle = styled.div`
  flex: none; // we don't want to grow or shrink
  border-radius: 50%; // circle the square

  box-sizing: content-box; // don't include the border in the size

  border: 1px solid ${colours.offBlack};
  width: 16px;
  height: 16px;

  margin-top: 4px;
  margin-right: ${horizontal.s};

  input[type="radio"]:checked + div > & {
    border-color: ${colours.white};
    box-shadow: inset 0 0 0 3px ${colours.offBlack};
    background: ${colours.white};
  }
`

const InputLabel = styled.span`
  display: block;
  ${typographyStyles.body};
`

const InputDescription = styled.span`
  display: block;
  ${typographyStyles.bodySmall};
`

const IconWrapper = styled.span`
  flex: none;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

interface RadioButton<Value> {
  label: string
  description?: string
  value: Value
  icon?: ReactNode
}

interface RadioButtonProps<Value> extends RadioButton<Value> {
  // Required
  name: string
  onChange: (value: Value) => void
  isSelected: boolean
  required: boolean

  // Optional
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onClick?: React.MouseEventHandler<HTMLInputElement>
}

const RadioButton = <Value extends string>({
  name,
  label,
  description,
  value,
  isSelected,
  onKeyDown,
  onClick,
  onChange,
  required,
  icon,
}: RadioButtonProps<Value>): React.ReactElement => (
  <Label>
    <InvisibleTabbableInput
      type="radio"
      name={name}
      value={value}
      /* The onChange handler is only called for an <input type="radio"> when
       * it becomes checked, not when it becomes unchecked.
       *
       * So when a radio button becomes checked it will call the group's
       * onChange handler with the newly checked radio button's value.
       */
      onChange={_event => onChange(value)}
      onKeyDown={onKeyDown}
      onClick={onClick}
      required={required}
      checked={isSelected}
    />
    <RadioButtonInner>
      <RadioButtonCircle />
      <TextContainer>
        <div>
          <InputLabel>{label}</InputLabel>
          {description && <InputDescription>{description}</InputDescription>}
        </div>
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </TextContainer>
    </RadioButtonInner>
  </Label>
)

type RadioButtonLayout = "row" | "column"

const Legend = styled.legend`
  width: 100%;
`

interface Props<Value> {
  // Required
  buttons: RadioButton<Value>[]
  layout: Layout<RadioButtonLayout> | RadioButtonLayout
  name: string
  onChange: RadioButtonProps<Value>["onChange"]
  title: string

  // Optional
  ariaLabelledBy?: string
  description?: string | React.ReactElement
  disabled?: boolean
  errorMessage?: string
  required?: boolean
  selectedValue?: Value
  onKeyDown?: RadioButtonProps<Value>["onKeyDown"]
  onClick?: RadioButtonProps<Value>["onClick"]
}

const RadioButtonGroup = <Value extends string>({
  name,
  buttons,
  onChange,
  title,
  ariaLabelledBy,
  description,
  disabled,
  errorMessage = "",
  selectedValue,
  layout,
  required = false,
  onKeyDown,
  onClick,
}: Props<Value>): React.ReactElement => {
  layout = defaultSameLayoutString(layout)

  return (
    <FieldSet aria-labelledby={ariaLabelledBy} disabled={disabled}>
      {!title && !description ? null : (
        <Legend>
          <QuestionText title={title} description={description} />
        </Legend>
      )}
      <RadioButtonContainer layout={layout}>
        {buttons.map(item => (
          <RadioButton
            key={item.value}
            {...item}
            name={name}
            isSelected={item.value === selectedValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onClick={onClick}
            required={required}
          />
        ))}
      </RadioButtonContainer>
      {errorMessage && <Error message={errorMessage} />}
    </FieldSet>
  )
}

export default RadioButtonGroup
